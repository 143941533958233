$tint: #034694;
.regular-container {
    display: flex ;
    flex-direction: column;
    background: $tint;
    justify-content: top;
    align-items: left;
    padding-left: 15%;
    padding-right: 15%;
    justify-content: center;
    align-items: top;
    height: 800px;
    position: relative;
    z-index: 1;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSI+CjxyZWN0IHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCIgZmlsbD0iIzI4MjgyOCI+PC9yZWN0Pgo8Y2lyY2xlIGN4PSIzIiBjeT0iNC4zIiByPSIxLjgiIGZpbGw9IiMzOTM5MzkiPjwvY2lyY2xlPgo8Y2lyY2xlIGN4PSIzIiBjeT0iMyIgcj0iMS44IiBmaWxsPSJibGFjayI+PC9jaXJjbGU+CjxjaXJjbGUgY3g9IjEwLjUiIGN5PSIxMi41IiByPSIxLjgiIGZpbGw9IiMzOTM5MzkiPjwvY2lyY2xlPgo8Y2lyY2xlIGN4PSIxMC41IiBjeT0iMTEuMyIgcj0iMS44IiBmaWxsPSJibGFjayI+PC9jaXJjbGU+Cjwvc3ZnPg==");
}

.category-preview, .archive-preview {
    text-align: center;
    list-style: none;
    margin-left: 0;
    max-width: 90%;

    display: table;
    padding-bottom: 15px;
    @media screen and (max-width: 768px){
        height: 100px;
    }

    
}

.projects-h1 {
    color:white ;
    font-size: px;
    text-align: left;
    
    @media screen and (max-width: 768px){
        font-size: 30px
    }
    @media screen and (max-width: 480px){
        font-size: 25px
    }

}


.projects-p {
    margin-top: 5px;
    margin-bottom: 25px;

    color: white;
    font-size: 24px;
    text-align: left;

    @media screen and (max-width: 768px){
        font-size: 24px
    }
    @media screen and (max-width: 480px){
        font-size: 18px
    }
}

.projects-content {
    z-index: 3;
    max-width: 1200px;
    position: relative;
    // padding: 0px 24px;
    // padding-top: 50px;
    display: position;
    flex-direction: row;
    align-items: top;

    @media screen and (max-width: 768px){
        padding-top: 10%;
    }
    padding-top: 20px;

}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

.contact-button {
    border-radius: 80px;
    background: $tint;
    white-space: nowrap;
    padding: 19px 48px;
    margin-top: 10px;
    margin-left: 30%;
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: top;
    width: 40%;
    transition:  all 0.2s ease-in-out;
    font-size: larger;
    &:hover {
        transition:  all 0.2s ease-in-out;
        background-color: black;
        color: white;
    }
    @media screen and (max-width: 768px){
        width: 60%;
        margin-left: 20%;
    
    }
    
    }