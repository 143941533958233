$tint: #034694;
body {
    background-color: white;        
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSI+CjxyZWN0IHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCIgZmlsbD0iIzI4MjgyOCI+PC9yZWN0Pgo8Y2lyY2xlIGN4PSIzIiBjeT0iNC4zIiByPSIxLjgiIGZpbGw9IiMzOTM5MzkiPjwvY2lyY2xlPgo8Y2lyY2xlIGN4PSIzIiBjeT0iMyIgcj0iMS44IiBmaWxsPSJibGFjayI+PC9jaXJjbGU+CjxjaXJjbGUgY3g9IjEwLjUiIGN5PSIxMi41IiByPSIxLjgiIGZpbGw9IiMzOTM5MzkiPjwvY2lyY2xlPgo8Y2lyY2xlIGN4PSIxMC41IiBjeT0iMTEuMyIgcj0iMS44IiBmaWxsPSJibGFjayI+PC9jaXJjbGU+Cjwvc3ZnPg==");


}
.display-container {
    height: 1200px;
    width: 80%;
    color: white;
    margin-right: auto;
    margin-left: auto;
    padding: 1rem;
    // border: solid;
    @media screen and (max-width: 768px) {
        max-width: 100%;
    }
}
.display-img {

}
.display-title-container {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        margin-right: 0px;
      }

}
.display-title {
    font-size: 40px;
    @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    color: $tint;
}
.live-symbol {
    float:right;
    margin-left: 10%;
    text-align: left;


}
.header-link {
    text-decoration: none;
    margin-left: 25px;
    font-size: 20px;
    text-align: center;
    align-items: center;
    color: white;

    &:hover {
        color: $tint;
    }
    @media screen and (max-width: 768px) {
        font-size: 10px;
        margin-left: 10px;

      }
}

.display-tech {
    display:flex;
    list-style: none;
    max-width: 100%;
    @media screen and (max-width: 768px) {
        display: wrap;
    }
}
.display-tech-item {
    margin-left: 10px;
    padding: 5px;
    border-radius: 20px;
    background-color: $tint;
    color: white;
    text-decoration: none;
    cursor: default;
}

.display-images-container {
    width: 100%;
    height: 100%;
    justify-content: space-between;
}
.display-img {
    margin-top: 2%;
    width: 210px;
    opacity: 90%;
    margin-right: 50px;
    border: 3px solid $tint;
    border-radius: 15px;

    &:hover {
        opacity: 100%;
    }
    @media screen and (max-width: 768px) {
        width: 100px;
        margin: 20px 5px;

    }
  }

  .description-p {
      max-width: 80%;
      font-size: 18px;
      margin-bottom: 5px;
      @media screen and (max-width: 768px) { max-width: 100%;}
  }

  .body-title {
      text-decoration: underline;
      margin-top: 20px;
      color: $tint;
  }
  .features-list-item {
    font-size: 18px;
    margin-left: 20px;

  }