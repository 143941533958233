$tint: #034694;
.contact-container {
    display: flex;
    justify-content: center;
    align-items: top;
    padding: 0 30px;
    height: 800px;
    position: relative;
    z-index: 1;
}
.contact-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
}
.img-bg {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit:cover;
    background: #232a34;
    z-index: 1;
}


.contact-h1 {
    color:white ;
    font-size: 48px;
    text-align: center;
    
    @media screen and (max-width: 768px){
        font-size: 40px
    }
    @media screen and (max-width: 480px){
        font-size: 32px
    }

}


.contact-p {
    margin-top: 5px;
    margin-bottom: 25px;

    color: white;
    font-size: 24px;
    text-align: center;

    @media screen and (max-width: 768px){
        font-size: 24px
    }
    @media screen and (max-width: 480px){
        font-size: 18px
    }
}

.contact-content {
    z-index: 3;
    max-width: 1200px;
    position: relative;
    padding: 0px 24px;
    padding-top: 50px;
    display: position;
    flex-direction: row;
    align-items: top;

    @media screen and (max-width: 768px){
        padding-top: 20%;
    }
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}
input, select, textarea {
    width: 100%; /* Full width */
    padding: 12px; /* Some padding */ 
    border: 1px solid #ccc; /* Gray border */
    border-radius: 4px; /* Rounded borders */
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    margin-top: 6px; /* Add a top margin */
    margin-bottom: 16px; /* Bottom margin */
    resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
  }
  
  /* Style the submit button with a specific background color etc */
  input[type=submit] {
    border-radius: 80px;
    background: $tint;
    white-space: nowrap;
    padding: 19px 48px;
    margin-top: 10px;
    margin-left: 30%;
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: top;
    width: 40%;
    transition:  all 0.2s ease-in-out;
    font-size: larger;
    &:hover {
        transition:  all 0.2s ease-in-out;
        background-color: black;
        color: white;
    }
    @media screen and (max-width: 768px){
        width: 60%;
        margin-left: 20%;
    
    }
  }
  textarea {
    height: 100px;
  }
  
  /* When moving the mouse over the submit button, add a darker green color */
  input[type=submit]:hover {
    background-color: #45a049;
  }
  
  /* Add a background color and some padding around the form */
  .container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
  }