$tint: #034694;
.hero-container {
    display: flex;
    background: 0c0c0c;
    justify-content: center;
    align-items: top;
    padding: 0 30px;
    height: 800px;
    // max-height: 1200px;
    position: relative;
    z-index: 1;
}

.hero-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100;
    overflow: hidden;
}

.video-bg {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit:cover;
    background: #232a34;
    z-index: 1;
}

.hero-content {
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 0px 24px;
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: top;

    @media screen and (max-width: 768px){
        padding-top: 20%;
    }
}
.hero-h1 {
    color: #fff;
    font-size: 48px;
    text-align: center;
    
    @media screen and (max-width: 768px){
        font-size: 40px
    }
    @media screen and (max-width: 480px){
        font-size: 32px
    }

}

.hero-p {
    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    max-width: 600px;

    @media screen and (max-width: 768px){
        font-size: 24px
    }
    @media screen and (max-width: 480px){
        font-size: 18px
    }
}
body {
    background-color: black
}

.main-button {
border-radius: 80px;
background: black;
white-space: nowrap;
padding: 19px 48px;
margin-top: 50px;
margin-left: 30%;
color: white;
outline: none;
border: none;
cursor: pointer;
display: flex;
justify-content: center;
align-items: top;
width: 40%;
transition:  all 0.2s ease-in-out;
font-size: larger;
&:hover {
    transition:  all 0.2s ease-in-out;
    background-color: $tint;
    color: black;
}
@media screen and (max-width: 768px){
    width: 60%;
    margin-left: 20%;

}

}