$light: white;
$dark: black;
$tint: #034694;
.nav {
  background: $dark;
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem calc((100vw - 1300px) / 2);
  z-Index: 10;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.2);
  position: sticky;
  top: 0;

}

.nav-link {
  text-decoration: none;
  color: $light;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &:hover {
    color:$tint;
  }
  font-smooth: always;

}
.nav-link-title {
  text-decoration: none;
  color: $light;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &:hover {
    color:$tint;
  }
  font-smooth: antialiased;
  opacity: 75%;

}

.nav-link-external {
  text-decoration: none;
  color: tint;
  display: flex;
  align-items: center;
  font-size: 0.5rem;
  padding: 0 0.3rem;
  height: 80%;
  cursor: pointer;
  &:hover {
    color:white;
  }
  font-smooth: auto;

}

.bars {
  display: none;
  color: #fff;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
}
.nav-menu {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  
  
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.burger-menu {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-100%, 90%);
  font-size: 1.8rem;
  color: $light;
  &:hover {
    color:tint;
  }
  

}
.icon {
  font-size: 3rem;
  padding-left: 10px;
  // color: tint;
    &:hover {
    color:tint;
  }
}
.icon-external {
  font-size: 2.8rem;
  padding-left: 10px;
  color: tint;
    &:hover {
    color:white;
  }
}

