@font-face { font-family: fut; font-weight: bold; src: url('./fonts/futPt.ttf');}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "fut";
  
}

code {
  font-family: "Futura PT Medium";
}

@import url(https://fonts.googleapis.com/css?family=Roboto:100);

.container {
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
  
}